import React, { useRef } from "react";
import styles from "../auto-scroll/auto-scroll.module.css";
import AutoScrollMockup from "../auto-scroll-mockup/auto-scroll-mockup";
function Autoscroll() {
  const tryItRef = useRef();
  return (
    <div className={styles["auto-scroll"]}>
      <div className={styles["left-square"]}>
        <AutoScrollMockup
          className={styles["mockup"]}
          onClick={() => tryItRef.current.click()}
        />
      </div>
      <div className={styles["right-column"]}>
        <div className={styles["content"]}>
          <h1>Autoscroll-container</h1>
          <p className={styles["first-p"]}>
            It is a react component for improving scroll functionality. By using
            this component developers have full control over scrolling to
            provide a better user experience.
          </p>
          <p className={styles["second-p"]}>
            It is an example of{" "}
            <a
              className={styles["simple-link"]}
              href="https://www.npmjs.com/settings/makannew/packages"
              target="_blank"
              rel="noopener noreferrer"
            >
              my contributions
            </a>{" "}
            to the programming community.
          </p>
          <div>React, Javascript</div>

          <a
            ref={tryItRef}
            className={`btn ${styles["try-it"]}`}
            href="https://makannew.github.io/auto-scroll-container/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Try it!
          </a>
          <a
            className={`btn ${styles["code"]}`}
            href="https://github.com/makannew/auto-scroll-container"
            target="_blank"
            rel="noopener noreferrer"
          >
            Code
          </a>
        </div>
      </div>
    </div>
  );
}

export default Autoscroll;
