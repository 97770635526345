import React from "react";
import styles from "../home/home.module.css";
import Roadtrain from "../../components/roadtrain/roadtrain";
import IShare from "../../components/i-share/i-share";
import Autoscroll from "../../components/auto-scroll/auto-scroll";
import MyStory from "../../components/my-story/my-story";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";

function Home() {
  return (
    <div className={styles.home}>
      <Header />
      <div className={styles.gap}></div>
      <IShare />
      <div className={styles.gap}> </div>
      <Autoscroll />
      <div className={styles.gap}> </div>
      <Roadtrain />
      <div className={styles.gap}> </div>
      <MyStory />
      <div className={styles.gap}> </div>

      <Footer />
    </div>
  );
}

export default Home;
