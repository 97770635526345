import React, { useRef } from "react";
import styles from "../roadtrain/roadtrain.module.css";
import RoadTrainMockup from "../roadtrain-mockup/roadtrain-mockup";

function Roadtrain() {
  const tryItRef = useRef();

  return (
    <div className={styles.roadtrain}>
      <div className={styles["left-column"]}>
        <div className={styles["bottom-color-left"]}></div>
        <div className={styles["content"]}>
          <h1>Roadtrain Game</h1>
          <p className={styles["first-p"]}>
            This game simulates a 3D world with physical properties such as
            gravity, momentum, and friction.
          </p>
          <p className={styles["second-p"]}>
            To build it I developed a minimal game engine based on Three.js and
            Cannon.js in javascript to extract the maximum power of the browser.
            I have a good understanding of math and physic so I look forward to
            challenging myself in this kind of unique project.
          </p>
          <div>
            <h6>Javascript</h6>
            <h6>Three.js</h6>
            <h6>Cannon.js</h6>
          </div>
          <a
            ref={tryItRef}
            className={`btn ${styles["try-it"]}`}
            href="https://makannew.github.io/roadtrain/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Try it!
          </a>
          <a
            className={`btn ${styles["code"]}`}
            href="https://github.com/makannew/roadtrain"
            target="_blank"
            rel="noopener noreferrer"
          >
            Code
          </a>
        </div>
      </div>

      <div className={styles["right-square"]}>
        <div className={styles["bottom-color-right"]}></div>

        <RoadTrainMockup
          onClick={() => tryItRef.current.click()}
          className={styles["mockup"]}
        />
      </div>
    </div>
  );
}

export default Roadtrain;
