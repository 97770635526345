import React from "react";
import styles from "../my-story/my-story.module.css";

function MyStory() {
  return (
    <div className={styles["my-story"]}>
      <div className={styles["content"]}>
        <h2>My story</h2>
        <p>
          &#8220;I was among those kids who had been fascinated by computer
          games. I learned my first programming language in school and it was a
          starting point for diving deeply into the computer world far to the
          point that I was building fun software with assembly language.
        </p>
        <p>
          Because I was happy with my programming skills so I got my bachelor's
          degree in chemical engineering to follow my other dreams as well.
        </p>

        <p>
          I had the opportunity to work in a team where we were responsible for
          the design, build, and automate process equipment. So while I was
          following my chemical engineering and mechanical design interests I
          fulfilled the coding part by programming PLC, Microcontrollers, and
          HMI monitoring systems.
        </p>
        <p>
          In 2017 an important event in my life forced me to pick only one of my
          interests as my future career and with no doubt I picked programming.
        </p>
        <p>
          I started to self-study of high-level programming languages like
          python and javascript. Very soon javascript became my favorite
          language and I decided to become a web developer. I learned necessary
          tools and frameworks like Html, CSS, react, node.js, express, ajax,
          rest API, cloud functions… and practice them in action by building
          real-world projects. Now, I can call myself a front end
          developer.&#8221; <span>-Makan Edrisi</span>
        </p>
      </div>
    </div>
  );
}

export default MyStory;
