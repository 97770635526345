import React from "react";
import styles from "../footer/footer.module.css";
import makan from "../../assets/makan.jpg";
import github from "../../assets/github.png";
import linkdin from "../../assets/linkdin.png";
import stackoverflow from "../../assets/stackoverflow.png";
import twitter from "../../assets/twitter.png";
import medium from "../../assets/medium.png";
import youtube from "../../assets/youtube.png";

function Footer() {
  return (
    <div className={styles["footer"]}>
      <div className={styles["left-column"]}>
        <div className={styles["photo-container"]}>
          <img className={styles["makan"]} src={makan} alt="makan" />
        </div>
        <div className={styles["layout"]}>
          <a
            href="https://github.com/makannew"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className={styles["github"]} src={github} alt="github" />{" "}
          </a>

          <a
            href="https://www.linkedin.com/in/makan-edrisi-2178a015"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className={styles["linkdin"]} src={linkdin} alt="linkdin" />
          </a>

          <a
            href="https://stackoverflow.com/users/9581649/makan"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className={styles["stackoverflow"]}
              src={stackoverflow}
              alt="stackoverflow"
            />
          </a>

          <a
            href="https://twitter.com/edrisimakan?lang=en"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className={styles["twitter"]} src={twitter} alt="twitter" />
          </a>

          <a
            href="https://medium.com/@makan.edrisi"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className={styles["medium"]} src={medium} alt="medium" />
          </a>

          <a
            href="https://www.youtube.com/channel/UCYRUOc_Vl9L6epbs83Fm3eA?view_as=subscriber"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className={styles["youtube"]} src={youtube} alt="youtube" />
          </a>
        </div>
        <h6>Copyright &#9400; Makan Edrisi </h6>
      </div>
      <div className={styles["right-square"]}>
        <div className={styles["content"]}>
          <h1>My approach</h1>
          <p className={styles["first-p"]}>
            First, I analyze the project from different perspectives with great
            attention to details.
          </p>

          <p className={styles["second-p"]}>
            Then I will find the best possible solution to meet client
            expectations and deliver the final product in a timely manner by
            efficient usage of available resources.
          </p>

          <a
            className={`btn ${styles["email-me"]}`}
            href="mailto:makan.edrisi@gmail.com"
          >
            Email me
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
