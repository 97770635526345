import React from "react";
import styles from "../auto-scroll-mockup/auto-scroll-mockup.module.css";
import autoScrollGif from "../../assets/auto-scroll.gif";
import mac from "../../assets/mac.png";

function AutoScrollMockup({ className, ...rest }) {
  return (
    <div className={`${className} ${styles["mockup"]}`} {...rest}>
      <div className={styles["backlight"]}> </div>
      <img
        className={styles["auto-scroll-gif"]}
        src={autoScrollGif}
        alt="auto-scroll"
      />

      <img className={styles["mac-mockup"]} src={mac} alt="mac-mockup" />
      <div className={styles["gif-opacity"]}></div>
    </div>
  );
}

export default AutoScrollMockup;
