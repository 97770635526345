import React from "react";
import styles from "../i-share-mockup/i-share-mockup.module.css";
import iShare from "../../assets/i-share.gif";
import phoneMockup from "../../assets/samsung.png";

function IShareMockup({ className, ...rest }) {
  return (
    <div className={`${className} ${styles["mockup"]}`} {...rest}>
      <img className={styles["i-share-gif"]} src={iShare} alt="i-share" />
      <img
        className={styles["phone-mockup"]}
        src={phoneMockup}
        alt="phone-mockup"
      />
    </div>
  );
}

export default IShareMockup;
