import React from "react";
import styles from "../header/header.module.css";
import htmlCssJs from "../../assets/htmlcssjs.png";
import reactJs from "../../assets/reactjs.png";
import firebase from "../../assets/firebase.png";
import gcp from "../../assets/gcp.png";

function Header() {
  return (
    <div className={styles.header}>
      <div className={styles["left-square"]}>
        <div className={styles["center-text"]}>
          <h1>Makan Edrisi</h1>
          <h3>Front end developer</h3>
          <h3>Perth, Australia</h3>
        </div>
      </div>
      <div className={styles["right-column"]}>
        <div className={styles["right-top"]}>
          <img className={styles["reactjs"]} src={reactJs} alt="ReactJs" />
          <img className={styles["firebase"]} src={firebase} alt="Firebase" />
          <img className={styles["gcp"]} src={gcp} alt="GCP" />
        </div>
        <div className={styles["right-bottom"]}>
          <img
            className={styles["htmlcssjs"]}
            src={htmlCssJs}
            alt="html Css Js"
          />
        </div>
      </div>
    </div>
  );
}

export default Header;
