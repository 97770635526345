import React, { useEffect } from "react";
import useDelayedFunction from "use-delayed-function";

function CssResizeManager({ debounce = 0, widthCoverage = 1, children }) {
  const [debounceResize] = useDelayedFunction(handleResize, debounce);
  function handleResize() {
    const { frameHeight } = setViewportSize();
    setFontSize(frameHeight);
  }
  function setFontSize(frameHeight) {
    let largeFont = Math.round(frameHeight * 0.053);
    let headerFont = Math.round(frameHeight * 0.035);
    let normalFont = Math.round(frameHeight * 0.021);
    let smallFont = Math.round(frameHeight * 0.018);
    if (smallFont < 12) smallFont = 12;
    if (normalFont < 14) normalFont = 12;
    if (headerFont < 16) headerFont = 14;
    if (largeFont < 20) largeFont = 20;
    document.documentElement.style.setProperty(
      "--large-font",
      `${largeFont}px`
    );

    document.documentElement.style.setProperty(
      "--header-font",
      `${headerFont}px`
    );

    document.documentElement.style.setProperty(
      "--normal-font",
      `${normalFont}px`
    );

    document.documentElement.style.setProperty(
      "--small-font",
      `${smallFont}px`
    );
  }

  function setViewportSize() {
    const vh = document.documentElement.clientHeight;
    const vw = document.documentElement.clientWidth;
    let frameWidth, frameHeight;
    if (vh * 1.618 <= vw * widthCoverage) {
      frameWidth = vh * 1.618;
      frameHeight = vh;
    } else {
      frameWidth = vw * widthCoverage;
      frameHeight = (vw * widthCoverage) / 1.618;
    }
    frameWidth = Math.round(frameWidth);
    frameHeight = Math.round(frameHeight);
    document.documentElement.style.setProperty(
      "--frame-width",
      `${frameWidth}px`
    );

    document.documentElement.style.setProperty(
      "--frame-height",
      `${frameHeight}px`
    );
    return { frameWidth, frameHeight };
  }
  useEffect(() => {
    window.addEventListener("resize", debounceResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", debounceResize);
    };
  });
  return <>{children}</>;
}

export default CssResizeManager;
