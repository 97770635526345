import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Home from "./routes/home/home";
import About from "./routes/about/about";
import CssResizeManager from "../src/components/css-resize-manager/css-resize-manager";

function App() {
  return (
    <CssResizeManager>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/about" component={About} />
        </Switch>
      </BrowserRouter>
    </CssResizeManager>
  );
}

export default App;
