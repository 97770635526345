import React, { useRef } from "react";
import styles from "../i-share/i-share.module.css";
import IShareMockup from "../i-share-mockup/i-share-mockup";

function IShare() {
  const tryItRef = useRef();

  return (
    <div className={styles["i-share"]}>
      <div className={styles["left-column"]}>
        <div className={styles["content"]}>
          <h1>I-Share</h1>
          <p>
            Authentication, navigation, and user profile are basic features of
            many web applications, so I developed an app to demonstrate how I
            design these features. I made it from scratch and It doesn't rely on
            external libraries.
          </p>
          <div>
            <h6>Figma</h6>
            <h6>React</h6>
            <h6>Redux</h6>
            <h6>Firebase</h6>
            <h6>Firestore</h6>
          </div>

          <a
            ref={tryItRef}
            className={`btn ${styles["try-it"]}`}
            href="https://i-share-9cc64.web.app/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Try it!
          </a>
        </div>
      </div>
      <div className={styles["right-square"]}>
        <IShareMockup
          onClick={() => tryItRef.current.click()}
          className={styles["mockup"]}
        />
      </div>
    </div>
  );
}

export default IShare;
