import React from "react";
import styles from "../roadtrain-mockup/roadtrain-mockup.module.css";
import roadtrain from "../../assets/roadtrain.gif";
import gamepad from "../../assets/gamepad.png";
import gamepadAddress from "../../assets/gamepad-address.png";

function RoadTrainMockup({ className, ...rest }) {
  return (
    <div className={`${className} ${styles["mockup"]}`} {...rest}>
      <img id={styles["gamepad-mockup"]} src={gamepad} alt="gamepad-mockup" />
      <div id={styles["gamepad-opacity"]}></div>
      <img id={styles["roadtrain-gif"]} src={roadtrain} alt="roadtrain-gif" />
      <img
        id={styles["gamepad-address"]}
        src={gamepadAddress}
        alt="https://makannew.github.io/roadtrain/"
      />
    </div>
  );
}

export default RoadTrainMockup;
